import React, { Component } from 'react'
import withSizes from 'react-sizes'
import Image from '../../components/Image/Image'

class Highlights extends Component {
  componentDidMount() {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { isMobile } = this.props

    if (!isMobile && !this.state) {
      console.log('Both isMobile and state are null')
      return <section className="highlights" />
    }

    if (!isMobile) {
      return (
        <section className="highlights">
          <h2 className="highlights__heading heading heading--md">
            Highlights
          </h2>

          <div className="highlights__item row">
            <div className="highlights__text col-xs-12 col-sm-6">
              <p className="heading heading--sm">
                Ally provides visibility from idea to production
              </p>
              <p>
                Fully-customizable dashboards enable you to diagnose and act on
                team issues
              </p>
            </div>
            <div className="highlights__image col-xs-12 col-sm-6">
              <Image
                filename="home-highlight-1.png"
                alt="Visibility from idea to production"
              />
            </div>
          </div>

          <div className="highlights__item row">
            <div className="highlights__image col-xs-12 col-sm-6">
              <Image
                filename="home-highlight-2.png"
                alt="Activities feed gelp you increase transparency"
              />
            </div>
            <div className="highlights__text col-xs-12 col-sm-6">
              <p className="heading heading--sm">
                Activities feed helps you increases transparency
              </p>
              <p>
                Activities feed brings together important actions related to
                your team.
              </p>
            </div>
          </div>

          <div className="highlights__item row">
            <div className="highlights__text col-xs-12 col-sm-6">
              <p className="heading heading--sm">
                Alerts help you stay on top of your day
              </p>
              <p>
                Receive alerts in Slack or Microsoft Teams when something has or
                has not happened.
              </p>
            </div>
            <div className="highlights__image col-xs-12 col-sm-6">
              <Image
                filename="home-highlight-1.png"
                alt="Alerts help you stay on top of your day"
              />
            </div>
          </div>
        </section>
      )
    } else {
      return (
        <section className="highlights highlights--mobile">
          <h2 className="highlights__heading heading heading--md">
            Highlights
          </h2>

          <div className="highlights__item row">
            <div className="highlights__text col-xs-12">
              <p className="heading heading--sm">
                Ally provides visibility from idea to production
              </p>
              <p>
                Fully-customizable dashboards enable you to diagnose and act on
                team issues
              </p>
            </div>
            <div className="highlights__image highlights__image--full col-xs-12">
              <Image
                filename="home-highlight-1-mobile.png"
                alt="Fully-cusomizable dashboards"
              />
            </div>
          </div>

          <div className="highlights__item row">
            <div className="highlights__text col-xs-12">
              <p className="heading heading--sm">
                Activities feed helps you increases transparency
              </p>
              <p>
                Activities feed brings together important actions related to
                your team.
              </p>
            </div>
            <div className="highlights__image col-xs-12">
              <Image
                filename="home-highlight-2.png"
                alt="Important actions related to yout team"
              />
            </div>
          </div>

          <div className="highlights__item row">
            <div className="highlights__text col-xs-12 col-sm-6">
              <p className="heading heading--sm">
                Alerts help you stay on top of your day
              </p>
              <p>
                Receive alerts in Slack or Microsoft Teams when something has or
                has not happened.
              </p>
            </div>
            <div className="highlights__image col-xs-12">
              <Image
                filename="home-highlight-3-mobile.png"
                alt="Receive alerts"
              />
            </div>
          </div>
        </section>
      )
    }
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 792
})

export default withSizes(mapSizesToProps)(Highlights)
