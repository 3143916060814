import React from 'react'
import Video from '../../components/Video/Video'

const Hero = () => (
  <section className="hero row">
    <div className="hero__text col-xs-12 col-sm-6">
      <h1 className="heading heading--big">
        Do you know what happens around your team?
      </h1>
      <p>
        Solve problems, find answers, and optimize your software team with
        Ally’s easy-to-use team visibility platform.
      </p>
      <a
        href="https://app.getally.com"
        className="hero__btn btn btn--blue btn--big"
      >
        Get Started
      </a>
      <ul className="hero__list list list--marks">
        <li>Free 14-day trial</li>
        <li>Easy setup</li>
        <li>No card required</li>
      </ul>
    </div>
    <div className="hero__video col-xs-12 col-sm-6 hidden-mobile">
      <Video />
    </div>
  </section>
)

export default Hero
