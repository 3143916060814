import React from 'react'

import Layout from '../components/Layout/Layout'
import Container from '../components/Container/Container'
import Seo from '../components/Seo/Seo'

import Hero from '../sections/Hero/Hero'
import Showcase from '../sections/Showcase/Showcase'
import Adapts from '../sections/Adapts/Adapts'
import Highlights from '../sections/Highlights/Highlights'
import Connect from '../sections/Connect/Connect'
import Try from '../sections/Try/Try'

const IndexPage = () => {
  return (
    <Layout pageName="home">
      <Seo />
      <Container>
        <Hero />

        <Showcase />

        <Adapts />

        <Highlights />

        <Connect />

        <Try />
      </Container>
    </Layout>
  )
}

export default IndexPage
