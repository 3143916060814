import React from 'react'
import Image from '../../components/Image/Image'

const Connect = () => (
  <section className="connect">
    <h2 className="connect__heading heading heading--md">
      Connect your tools with Ally
    </h2>
    <p>
      Spend less time juggling tabs and more time focusing on meaningful work.
    </p>
    <ul className="connect__list">
      <li>
        <Image filename="jira" className="connect__icon" alt="Jira" />
        <p className="extra-small">Jira</p>
      </li>
      <li>
        <Image filename="trello" className="connect__icon" alt="Trello" />
        <p className="extra-small">Trello</p>
      </li>
      <li>
        <Image filename="github" className="connect__icon" alt="Github" />
        <p className="extra-small">GitHub</p>
      </li>
      <li>
        <Image filename="slack" className="connect__icon" alt="Slack" />
        <p className="extra-small">Slack</p>
      </li>
      <li>
        <Image
          filename="microsoft-teams"
          className="connect__icon"
          alt="Microsoft Teams"
        />
        <p className="extra-small">Microsoft Teams</p>
      </li>
      <li>
        <Image filename="gsuite" className="connect__icon" alt="GSuite" />
        <p className="extra-small">GSuite</p>
      </li>
      <li>
        <Image
          filename="confluence"
          className="connect__icon"
          alt="Confluence"
        />
        <p className="extra-small">Confluence</p>
      </li>
      <li>
        <Image filename="bitbucket" className="connect__icon" alt="Bitbucket" />
        <p className="extra-small">Bitbucket</p>
      </li>
      <li>
        <Image filename="circleci" className="connect__icon" alt="CircleCI" />
        <p className="extra-small">CircleCI</p>
      </li>
    </ul>
    <p className="heading heading--small">Or build your own with our APIs.</p>
    <p className="hidden-mobile">
      Or build your own with our APIs. Get even more from the tools you rely on.
      <br />
      Configure Ally for your team’s unique needs with the Ally API.
    </p>
    <p className="visible-only-mobile">
      Get even more from the tools you rely on. Configure Ally for your team’s
      unique needs with the Ally API.
    </p>
  </section>
)

export default Connect
